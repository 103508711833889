import React, { useCallback, useEffect } from 'react';
import { Box, Center, Divider, HStack, Text, VStack } from 'native-base';
import { observer } from 'mobx-react-lite';
import { StackScreenProps } from '@react-navigation/stack';

import { WarehousesParamList } from './GrowerRootStackNavigator';
import { useGrowerRootStore } from '../../Stores/GrowerPortal/GrowerRootStoreProvider';
import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import InfiniteListPage from '../../Components/pages/InfiniteListTablePage';
import Label from '../../Components/atoms/Label';
import Bubble from '../../Components/atoms/Bubble';
import IconButton from '../../Components/atoms/IconButton';
import { useNavigation } from '@react-navigation/core';
import { Colors } from '../../Colors';

const numberFormatter = Intl.NumberFormat('en-au', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

type Props = StackScreenProps<WarehousesParamList, 'list'>;

const WarehousesListPageContainer = observer<Props>(props => {
  const { warehouseListStore } = useGrowerRootStore();
  const navigation = useNavigation();
  const dataState = warehouseListStore.getEntities();

  const onRefresh = useCallback(() => {
    warehouseListStore.refreshEntities();
  }, [warehouseListStore]);

  // Run this effect every time the data updates
  useEffect(() => {
    // Start a request to refresh if no data exists
    if (!dataState) {
      onRefresh();
      return;
    }
  }, [dataState, onRefresh]);

  const data = dataState ?? AsyncLoadable.loading();

  const listData = AsyncLoadable.mapValue(data, data => {
    return data.map(warehouse => ({
      headerTitle: warehouse.siteName,
      data: warehouse.levels,
    }));
  });

  useEffect(() => {
    const headerLeft = () => (
      <Box marginX="3">
        <IconButton icon="refresh" onPress={onRefresh} size={24} />
      </Box>
    );

    navigation.setOptions?.({ headerLeft });
  }, [navigation]);

  return (
    <InfiniteListPage
      title="Warehouse Levels"
      type="flat-list"
      loadable={listData}
      onRefresh={onRefresh}
      onListEndReached={() => {}}
      renderItem={({ item }) => (
        <>
          <Bubble background="green.100" marginTop marginX>
            <VStack>
              <Center paddingBottom={2}>
                <HStack space={2}>
                  <Label variant="header" bold>
                    {item.headerTitle}
                  </Label>
                </HStack>
              </Center>
              <Label wrap variant="header">Levels</Label>
              {item.data.map((l, i) => (
                <Box borderRadius="10" paddingX="5" paddingY="1" mt={i > 0 ? "5" : "2"} backgroundColor={Colors.GREEN.darken(0.05).hex()}>
                  <HStack flex={1} justifyContent="space-between">
                    <VStack>
                      <Label>Type: {l.commodityNameAndGrade}</Label>
                      <Label>Season: {l.commoditySeason}</Label>
                    </VStack>
                    <VStack>
                      <Label>Amount:</Label>
                      <Label>
                        {numberFormatter.format(l.amount)} {l.unitName}
                      </Label>
                    </VStack>
                  </HStack>
                </Box>
              ))}
            </VStack>
          </Bubble>
        </>
      )}
    />
  );
});

export default WarehousesListPageContainer;
