import React from 'react';
import { Box, Text } from 'native-base';
import { observer } from 'mobx-react-lite';
import { createStackNavigator } from '@react-navigation/stack';
import { LinkingOptions, NavigationContainer, NavigatorScreenParams } from '@react-navigation/native';
import { BottomTabNavigationProp, createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { useGrowerRootStore } from '../../Stores/GrowerPortal/GrowerRootStoreProvider';
import LoginPageContainer from '../LoginPageContainer';
import { ContractTypes, TicketTypes } from '@marango/api/src/models/Grower/Types';
import { Icon } from '../../Components/atoms/Icon';
import ContractListPageContainer from './ContractListPageContainer';
import ContractDetailPageContainer from './ContractDetailPageContainer';

import InvoiceListPageContainer from './InvoicesListPageContainer';
import WarehousesListPageContainer from './WarehousesListPageContainer';
import SettingsListPageContainer from '../Settings/SettingsListPageContainer';
import ChangePasswordPageContainer from '../Settings/ChangePasswordPageContainer';
import TicketListPageContainer from './TicketListPageContainer';
import TicketDetailPageContainer from './TicketDetailPageContainer';

export type ContractsParamList = { list: undefined; detail: { id: ContractTypes.Id } };
export type InvoicesParamList = { list: undefined };
export type WarehousesParamList = { list: undefined };
export type TicketsParamList = { list: undefined, detail: { id: TicketTypes.Id } };
export type SettingsParamList = { list: undefined, "change-password": undefined };

export type GrowerRootParamList = {
  contracts: NavigatorScreenParams<ContractsParamList>;
  invoices: NavigatorScreenParams<InvoicesParamList>;
  warehouses: NavigatorScreenParams<WarehousesParamList>;
  tickets: NavigatorScreenParams<TicketsParamList>;
  settings: NavigatorScreenParams<SettingsParamList>;
  login: {};
};

export type GrowerPortalNavigationProp = BottomTabNavigationProp<GrowerRootParamList>

const linking: LinkingOptions<GrowerRootParamList> = {
  prefixes: ['http://localhost:3000'],
  config: {
    screens: {
      contracts: {
        initialRouteName: 'list',
        screens: {
          list: '/contracts',
          detail: '/contracts/:id',
        },
      },
      invoices: {
        initialRouteName: 'list',
        screens: {
          list: '/invoices',
        },
      },
      warehouses: {
        initialRouteName: 'list',
        screens: {
          list: '/warehouses'
        }
      },
      tickets: {
        initialRouteName: 'list',
        screens: {
          list: '/tickets',
          detail: '/tickets/:id',
        }
      },
      settings: {
        initialRouteName: 'list',
        screens: {
          list: '/settings',
          "change-password" : '/settings/change-password'
        }
      }
    },
  },
};

const TabNav = createBottomTabNavigator<GrowerRootParamList>();
const ContractStack = createStackNavigator<ContractsParamList>();
const InvoicesStack = createStackNavigator<InvoicesParamList>();
const WarehousesStack = createStackNavigator<WarehousesParamList>();
const TicketsStack = createStackNavigator<TicketsParamList>();
const SettingsStack = createStackNavigator<SettingsParamList>();

const GrowerRootStackNavigator = observer(() => {
  const { navigationStore, userStore } = useGrowerRootStore();

  if (userStore.state._type === 'UserState.Initialising') {
    return <Box><Text>Loading...</Text></Box>
  }

  const isLoggedIn = userStore.state._type === 'UserState.Authenticated';

  const children = isLoggedIn ?
    <>
      <TabNav.Screen
        name="contracts"
        component={ContractStackScreen}
        options={{
          tabBarIcon: () => <Icon icon="contract" size={24} />,
          headerTitle: '',
          tabBarLabel: 'Contracts',
          headerShown: false,
        }}
      />
      <TabNav.Screen
        name="invoices"
        component={InvoicesStackScreen}
        options={{
          tabBarIcon: () => <Icon icon="invoice" size={24} />,
          headerTitle: '',
          tabBarLabel: 'Invoices',
          headerShown: false,
        }}
      />
      <TabNav.Screen
        name="warehouses"
        component={WarehousesStackScreen}
        options={{
          tabBarIcon: () => <Icon icon="warehouse" size={18} />,
          headerTitle: '',
          tabBarLabel: 'Warehouses',
          headerShown: false,
        }}
      />
      <TabNav.Screen
        name="tickets"
        component={TicketsStackScreen}
        options={{
          tabBarIcon: () => <Icon icon="ticket" size={18} />,
          headerTitle: '',
          tabBarLabel: 'Tickets',
          headerShown: false,
        }}
      />
      <TabNav.Screen
        name="settings"
        component={SettingsStackScreen}
        options={{
          tabBarIcon: () => <Icon icon="cog" size={18} />,
          headerTitle: '',
          tabBarLabel: 'Settings',
          headerShown: false,
        }}
      />
    </>
    : <>
      <TabNav.Screen
        name="login"
        component={LoginPageContainer}
        options={{ headerShown: false, tabBarStyle: { display: 'none' } }}
      />
    </>


  return (
    <NavigationContainer ref={navigationStore.navigationRef} linking={linking}>
      <TabNav.Navigator initialRouteName="login" screenOptions={{ tabBarLabelPosition: 'below-icon' }}>
        {children}
      </TabNav.Navigator>
    </NavigationContainer>
  );
});

const ContractStackScreen = () => {
  return (
    <ContractStack.Navigator
      initialRouteName="list"
      screenOptions={{
        animationTypeForReplace: 'pop',
        title: 'Grower Portal - Marango',
      }}>
      <ContractStack.Screen
        name="list"
        component={ContractListPageContainer}
      />

      <ContractStack.Screen
        name="detail"
        component={ContractDetailPageContainer}
      />
    </ContractStack.Navigator>
  );
};

const InvoicesStackScreen = () => {
  return (
    <InvoicesStack.Navigator
      initialRouteName="list"
      screenOptions={{
        animationTypeForReplace: 'pop',
        title: 'Invoices',
      }}>
      <InvoicesStack.Screen
        name="list"
        component={InvoiceListPageContainer}
      />
    </InvoicesStack.Navigator>
  );
};

const WarehousesStackScreen = () => {
  return (
    <WarehousesStack.Navigator
      initialRouteName="list"
      screenOptions={{
        animationTypeForReplace: 'pop',
        title: 'Warehouse Levels',
      }}>
      <WarehousesStack.Screen
        name="list"
        component={WarehousesListPageContainer}
      />
    </WarehousesStack.Navigator>
  );
};

const TicketsStackScreen = () => {
  return (
    <TicketsStack.Navigator
      initialRouteName="list"
      screenOptions={{
        animationTypeForReplace: 'pop',
        title: 'Weighbridge Tickets',
      }}>
      <TicketsStack.Screen
        name="list"
        component={TicketListPageContainer}
      />

      <TicketsStack.Screen
        name="detail"
        component={TicketDetailPageContainer}
      />
    </TicketsStack.Navigator>
  );
};

const SettingsStackScreen = () => {
  return (
    <SettingsStack.Navigator
      initialRouteName="list"
      screenOptions={{
        animationTypeForReplace: 'pop',
        title: 'Settings',
      }}>
      <SettingsStack.Screen
        name="list"
        component={SettingsListPageContainer}
      />
      <SettingsStack.Screen
        name="change-password"
        component={ChangePasswordPageContainer}
      />

    </SettingsStack.Navigator>
  );
};

export default GrowerRootStackNavigator;