import React from 'react';
import { Ticket } from '@marango/api/src/models/Grower/Ticket';
import { Box, HStack, VStack } from 'native-base';
import Bubble from '../../atoms/Bubble';
import { TableView } from '../../molecules/TableView';
import DateFormatter from '@marango/api/src/formatters/dateFormatter';
import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import ScrollViewWithRefresh from '../../molecules/Layout/ScrollViewWithRefresh';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import DialogBox from '../../atoms/DialogBox';
import { Colors } from '../../../Colors';
import Label from '../../atoms/Label';
import JobScheduleDetailIcon from '../../atoms/JobSchedule/FreightJobDetailIcon';
import { TicketTypes } from '@marango/api/src/models/Grower/Types';
import TelephoneLinker from '../../../Linkers/TelephoneLinker';

type Props = {
  ticket: AsyncLoadable<Ticket>;
  onRefresh: () => void;
};

const TicketDetailPage = (props: Props) => {
  const insets = useSafeAreaInsets();

  const isRefreshing = props.ticket._type === 'loading';
  const errorMessage =
    props.ticket._type === 'error' ? props.ticket.error : undefined;

  let loadedTicket: Ticket | undefined;
  if (props.ticket._type !== 'error') {
    loadedTicket = props.ticket.data;
  }

  return (
    <Box
      flex={1}
      bg={Colors.rgba('BACKGROUND_BLUE')}
      height="100%"
      width="100%">
      <ScrollViewWithRefresh
        refreshing={isRefreshing}
        onRefresh={props.onRefresh}
        insetBottom={insets.bottom}>
        {errorMessage && (
          <Box mt="5">
            <DialogBox
              title="Error Loading Ticket"
              description={errorMessage}
              severity="error"
            />
          </Box>
        )}
        {loadedTicket && <TicketDetailPageBody ticket={loadedTicket} />}
      </ScrollViewWithRefresh>
    </Box>
  );
};

const TicketDetailPageBody = (props: { ticket: Ticket }) => {
  return (
    <VStack mx="3" my="3">
      <HStack flex={1} mb="2" mx="2" justifyContent="space-between">
        <Label bold variant='header'>Ticket</Label>
        <HStack space="2">
          <Label variant='header'>{props.ticket.inOutString}</Label>
          <JobScheduleDetailIcon detailType={props.ticket.inOut === TicketTypes.InOut.In ? 'D' : 'P'} />
        </HStack>
      </HStack>
      <Bubble>
        <TableView.Container>
          <TableView.Cell
            name={props.ticket.ticketNo}
            value={DateFormatter(props.ticket.timestamp, 'complete')}
          />
          <TableView.Cell name={'Grade'} value={props.ticket.commodityKey} />
          <TableView.Cell
            name={'Mass'}
            value={`${props.ticket.massGross} T`}
          />
          <TableView.Cell
            name={'Tare'}
            value={`${props.ticket.massTare} T`}
          />
          <TableView.Cell
            name={'Net'}
            value={`${props.ticket.massNet} ${props.ticket.unitCode}`}
          />
          <TableView.Cell
            name={'Carrier Name'}
            value={`${props.ticket.carrierName}`}
          />
          <TableView.Cell
            name={'Carrier Rego'}
            value={`${props.ticket.carrierRego}`}
          />
          { props.ticket.siteName && <TableView.Cell
            name={'Site'}
            value={`${props.ticket.siteName}`}
          />}
          { props.ticket.siteAddress && <TableView.Cell
            name={'Site Address'}
            value={`${props.ticket.siteAddress}`}
          />}
          { props.ticket.sitePhone && <TableView.Cell
            name={'Site Phone'}
            value={props.ticket.sitePhone}
            onPress={() => TelephoneLinker(props.ticket.sitePhone!)}
          />}
        </TableView.Container>
      </Bubble>

      <Box mt="3" mb="2" mx="2">
        <Label bold variant='header'>Metrics</Label>
      </Box>
      <Bubble>
        <TableView.Container>
          {props.ticket.metrics.map(metric => (
            <TableView.Cell name={metric.name} value={metric.value} />
          ))}
        </TableView.Container>
      </Bubble>
    </VStack>
  );
};

export default TicketDetailPage;
