import { DateTime } from 'luxon';

export namespace AsyncLoadable {
  export type Loading<T> = {
    _type: 'loading';
    /**
     * Data is optional to represent existing
     * data that is simultaniously being reloaded
     * */
    data?: T;
  };

  export const loading = <T>(data?: T): Loading<T> => ({
    _type: 'loading',
    data,
  });

  export type LoadingFuture<T> = {
    _type: 'loadingFuture';
    data?: T;
  };

  export const loadingFuture = <T>(data?: T): LoadingFuture<T> => ({
    _type: 'loadingFuture',
    data,
  });

  export type Loaded<T> = {
    _type: 'loaded';
    data: T;
    lastRefresh: DateTime;
  };

  export const loaded = <T>(
    data: T,
    lastRefresh: DateTime = DateTime.now(),
  ): Loaded<T> => ({
    _type: 'loaded',
    data,
    lastRefresh,
  });

  export type Error<E> = {
    _type: 'error';
    error: E;
  };

  export const error = <E>(_error: E): Error<E> => ({
    _type: 'error',
    error: _error,
  });

  export const loadingIfUndefined = <T>(
    maybeAsyncLoadable: AsyncLoadable<T> | undefined,
  ): AsyncLoadable<T> => {
    return maybeAsyncLoadable !== undefined
      ? maybeAsyncLoadable
      : { _type: 'loading' };
  };

  export const loadingIfUndefinedRaw = <T>(
    maybeValue: T | undefined,
    lastRefresh: DateTime,
  ): AsyncLoadable<T> => {
    return maybeValue !== undefined
      ? { _type: 'loaded', data: maybeValue, lastRefresh }
      : { _type: 'loading' };
  };

  export const mapValue = <I, O>(
    loadableValue: AsyncLoadable<I>,
    map: (val: I) => O,
  ): AsyncLoadable<O> => {
    switch (loadableValue._type) {
      case 'error':
        return loadableValue;
        case 'loading':
        case 'loadingFuture':
        return loadableValue.data === undefined
          ? AsyncLoadable.loading()
          : AsyncLoadable.loading(map(loadableValue.data));
      case 'loaded':
        return AsyncLoadable.loaded(
          map(loadableValue.data),
          loadableValue.lastRefresh,
        );
    }
  };

  export const unwrap = <T>(loadable: AsyncLoadable<T>): T | undefined => {
    return loadable._type === 'error' ? undefined : loadable.data;
  };

  export const fromTanstackQuery = <T, E extends string>(
    data: T | undefined,
    error: E | undefined,
    isFetching: boolean,
    isFetchingNextPage: boolean
  ): AsyncLoadable<T, E> => {
    if (error) {
      return AsyncLoadable.error(error)
    } else if (isFetching) {
      return AsyncLoadable.loading(data)
    } else if (isFetchingNextPage) {
      return AsyncLoadable.loadingFuture(data)
    } else {
      if (!data) {
        throw new Error("AsyncLoadable.fromTanstackQuery is loaded with no data");
      }

      return AsyncLoadable.loaded(data)
    }
  }
}

export type AsyncLoadable<T, E = string> =
  | AsyncLoadable.Loading<T>
  | AsyncLoadable.LoadingFuture<T>
  | AsyncLoadable.Loaded<T>
  | AsyncLoadable.Error<E>;
