import { DateTime } from "luxon";
import { ITimestamped } from "../../time/ITimestamped";
import { DateString } from "../DateString";
import { CommodityTypes, ContractTypes } from "./Types";

export class Contract implements ITimestamped {
  public constructor(
    readonly contractId: ContractTypes.Id,
    readonly contractNo: ContractTypes.Number,
    readonly timestamp: DateTime,
    readonly basePricePerUnit: number,
    readonly deliveryFrom: DateString,
    readonly deliveryTo: DateString,
    readonly unitString: string,
    readonly unitsContracted: number,
    readonly unitsDelivered: number,
    readonly siteName: ContractTypes.SiteName,
    readonly comments: ContractTypes.Comments | undefined,

    readonly commoditySeason: CommodityTypes.Season,
    readonly commodityName: CommodityTypes.Name,
    readonly commodityGrade?: CommodityTypes.Grade,
    readonly commodityVariety?: CommodityTypes.Variety,
  ) { }

  get commodityKey(): string {
    let str: string = this.commodityName

    if (this.commodityGrade) {
      str += ` - ${this.commodityGrade}`
    }

    if (this.commodityVariety) {
      str += ` - ${this.commodityVariety}`
    }

    return str
  }
}