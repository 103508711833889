import _ from 'lodash';
import { Type } from 'class-transformer';
import {
  IsArray,
  IsDateString,
  IsNumber,
  IsOptional,
  IsString,
  MinLength,
  ValidateNested,
} from 'class-validator';

import { ToModel } from '../models/ToModel';
import { Contract } from '../models/Grower/Contract';
import { CommodityTypes, ContractTypes } from '../models/Grower/Types';
import { DateString, dateStringToLuxon } from '../models/DateString';
import { MarangoApiResponseCommodityDTO, MarangoApiResponseCommodityGradeDTO, MarangoApiResponseCommodityVarietyDTO, MarangoApiResponseUnitDTO } from './MarangoJobsResponseDTO';

export class MarangoContractsResponseDTO
  implements ToModel<ReadonlyArray<Contract>>
{
  @IsArray()
  @ValidateNested()
  @Type(_ => MarangoContractsResponseContractDTO)
  result!: ReadonlyArray<MarangoContractsResponseContractDTO>;

  toModel(): ReadonlyArray<Contract> {
    return this.result.map(j => j.toModel());
  }
}

export class MarangoContractDetailResponseDTO
  implements ToModel<Contract>
{
  @IsArray()
  @ValidateNested()
  @Type(_ => MarangoContractsResponseContractDTO)
  result!: MarangoContractsResponseContractDTO;

  toModel(): Contract {
    return this.result.toModel();
  }
}

export class MarangoContractsResponseContractDTO implements ToModel<Contract> {
  @IsNumber()
  contract_uid!: ContractTypes.Id

  @IsString()
  contract_no!: ContractTypes.Number

  @IsDateString()
  contract_date!: DateString

  @IsNumber()
  base_price_per_unit!: number

  @IsDateString()
  delivery_from!: DateString

  @IsDateString()
  delivery_to!: DateString

  @IsString()
  commodity_season!: CommodityTypes.Season

  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityDTO)
  commodity!: MarangoApiResponseCommodityDTO;

  @IsOptional()
  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityGradeDTO)
  base_commodity_grade!: MarangoApiResponseCommodityGradeDTO | null;

  @IsOptional()
  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityVarietyDTO)
  base_commodity_variety!: MarangoApiResponseCommodityVarietyDTO | null;

  @ValidateNested()
  @Type(_ => MarangoApiResponseUnitDTO)
  unit!: MarangoApiResponseUnitDTO;

  @IsNumber()
  units_approx!: number;

  @IsArray()
  @MinLength(1)
  @ValidateNested()
  @Type(_ => MarangoApiResponseUnitDTO)
  contract_site!: ReadonlyArray<MarangoApiResponseContractSiteDTO>;

  @IsString()
  contract_auto_comments!: ContractTypes.Comments | null;

  toModel(): Contract {
    return new Contract(
      this.contract_uid,
      this.contract_no,
      dateStringToLuxon(this.contract_date),
      this.base_price_per_unit,
      this.delivery_from,
      this.delivery_to,
      _.startCase(_.toLower(this.unit.multiple_name)),
      this.units_approx,
      0, // TODO: Add units_approx
      this.contract_site[0].site_name,
      this.contract_auto_comments ?? undefined,
      this.commodity_season,
      this.commodity.getCommodityName(),
      this.base_commodity_grade?.getCommodityGrade(),
      this.base_commodity_variety?.getCommodityVariety()
    );
  }
}

export class MarangoApiResponseContractSiteDTO {
  @IsString()
  readonly site_name!: ContractTypes.SiteName;
}
