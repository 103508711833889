import React, { useCallback, useEffect } from 'react';
import { Box, FlatList, SectionList, useDisclose, View } from 'native-base';
import { ISectionListProps } from 'native-base/lib/typescript/components/basic/SectionList/types';
import { FlatListProps, SectionListData, SectionListRenderItemInfo } from 'react-native';
import styled from 'styled-components';

import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import ScrollViewWithRefresh from '../molecules/Layout/ScrollViewWithRefresh';
import { useNavigation } from '@react-navigation/native';
import ScheduleTableErrorCell from '../molecules/ScheduleTable/ScheduleTableErrorCell';
import ScheduleTableLoading from '../molecules/ScheduleTable/ScheduleTableLoading';
import { ListRenderItemInfo } from 'react-native';

const TableContainer = styled(Box)({
  height: '100%',
  userSelect: 'none'
})

type SectionListHeaderData = {
  headerTitle: string;
};

type PropsBase<D> = {
  title: string;
  onRefresh: () => void;
  onListEndReached: () => void;
};

type PropsFlatList<D> = PropsBase<D> & {
  type: 'flat-list'
  loadable: AsyncLoadable<ReadonlyArray<D>>;
  renderItem: (data: ListRenderItemInfo<D>) => React.ReactElement;
}

type PropsSectionList<D> = PropsBase<D> & {
  type: 'section-list'
  loadable: AsyncLoadable<ReadonlyArray<SectionListData<D, SectionListHeaderData>>>;
  renderHeader: (data: SectionListData<D, SectionListHeaderData>) => React.ReactElement;
  renderItem: (data: SectionListRenderItemInfo<D, SectionListHeaderData>) => React.ReactElement;
}

type Props<D> =
  | PropsFlatList<D>
  | PropsSectionList<D>

function InfiniteList<D>(
  props: Props<D>
): JSX.Element | null {
  if (props.loadable._type === 'error') {
    return <ScheduleTableErrorCell message={props.loadable.error} />;
  } else {
    if (props.loadable.data === undefined) {
      return <ScheduleTableLoading />;
    } else {
      if (props.type === "section-list") {
        return <SectionList
          sections={props.loadable.data}
          renderSectionHeader={({ section }) => props.renderHeader(section)}
          renderItem={props.renderItem}
        />
      } else {
        return <FlatList data={props.loadable.data} renderItem={props.renderItem} />
      }

    }
  }
}

export default function InfiniteListPage<D>(
  props: Props<D>,
) {
  const navigation = useNavigation();

  const onRefresh = useCallback(() => {
    props.onRefresh();
  }, [props.onRefresh]);

  const isRefreshing = props.loadable._type === 'loading';

  useEffect(() => {
    navigation.setOptions?.({
      headerTitle: props.title
    });
  }, [props.title])

  return (
    <TableContainer>
      <ScrollViewWithRefresh
        background="white"
        refreshing={isRefreshing}
        onRefresh={onRefresh}>
          <InfiniteList {...props} />
      </ScrollViewWithRefresh>
    </TableContainer>
  );
}
