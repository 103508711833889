import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { UserLoginDetails } from '@marango/api/src/models/user/UserLoginDetails';
import { LoginForm } from '../Components/organism/LoginForm';
import LoginPage from '../Components/pages/LoginPage';

import { useRootStore } from '../Stores/useRootStore';

const LoginPageContainer = observer(() => {
  const { userStore } = useRootStore();

  const onLogin = useCallback(
    async (details: LoginForm.RequestDetails) => {
      if (UserLoginDetails.isUserLoginDetails(details)) {
        await userStore.login(details)
      }
    },
    [userStore],
  );

  const isLoading = userStore.isAuthentecating;

  return (
    <LoginPage
      serverId={"13"}
      onLogin={onLogin}
      isLoading={isLoading}
      error={userStore.error}
      logoColor="crocker"
    />
  );
});

export default LoginPageContainer;
