import React from 'react';
import { Divider } from 'native-base';
import ScheduleTableDetailRowSkeleton from '../../atoms/ScheduleTable/DetailRowSkeleton';

const ScheduleTableLoading = () => {
  return (
    <>
      <Divider key={'0-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'1-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'2-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'3-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'4-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'5-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'6-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'7-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'8-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'9-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'10-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'11-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'12-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'13-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'14-div'} />
      <ScheduleTableDetailRowSkeleton />
      <Divider key={'15-div'} />
    </>
  );
};

export default ScheduleTableLoading;
