import {
  MarangoJobsResponseDTO,
  MarangoJobsResponseJobDTO,
} from '../dtos/MarangoJobsResponseDTO';
import { MarangoLoginResponseDTO } from '../dtos/MarangoLoginResponseDTO';
import { MarangoVersionResponseDTO } from '../dtos/MarangoVersionResponseDTO';
import { FreightJob } from '../models/FreightJob/FreightJob';
import { UserLoginDetails } from '../models/user/UserLoginDetails';
import { DateRange } from '../time/dateRange';
import { ClassConstructor, plainToInstance } from 'class-transformer';
import { validate } from 'class-validator';
import { FreightJobTypes } from '../models/FreightJob/Types';
import { Contract } from '../models/Grower/Contract';
import { ContractTypes, TicketTypes } from '../models/Grower/Types';
import {
  MarangoContractDetailResponseDTO,
  MarangoContractsResponseDTO,
} from '../dtos/MarangoContractsResponseDTO';
import { AuthTokenDetails } from '../models/user/AuthTokenDetails';
import { RCTI } from '../models/Grower/RCTI';
import { MarangoRCTIsResponseDTO } from '../dtos/MarangoRCTIsResponseDTO';
import { MarangoWarehouseSitesResponseDTO } from '../dtos/MarangoWarehouseSiteResponseDTO';
import { Warehouse } from '../models/Grower/Warehouse';
import { ChangePasswordRequest } from '../models/user/ChangePasswordRequest';
import { MarangoChangePasswordResponseDTO } from '../dtos/MarangoChangePasswordResponseDTO';
import { MarangoTicketsResponseDTO } from '../dtos/MarangoTicketsResponseDTO';
import { Ticket } from '../models/Grower/Ticket';

export class MarangoApiError extends Error {
  statusCode?: number

  constructor(readonly raw: MarangoApiResponse.MarangoResponseError['errors']) {
    let message: string;

    if (typeof raw.message === 'string') {
      message = raw.message;
    } else if (Array.isArray(raw.message)) {
      message = raw.message[0];
    } else {
      const keys = Object.keys(raw.message);
      message = raw.message[keys[0]][0];
    }

    super(message);
    this.name = 'MarangoApiError';
    this.statusCode = raw.status_code
  }
}

export class MarangoApiInvalidResponseError extends Error {
  constructor(readonly message: string) {
    super(message);
    this.name = 'MarangoApiInvalidResponseError';
  }
}

type MarangoRequest<B = Record<string, string>, Q = Record<string, string>> = {
  serverId: MarangoApi.ServerId | UserLoginDetails.ServerId;
  route: MarangoApi.Route;
  method: 'GET' | 'POST';
  authToken?: MarangoApi.BearerToken;
  body?: B;
  queryParams?: Q;
};

export namespace MarangoApiResponse {
  export type Paginated<D> = {
    result: D,
    pages: PageCursor
  }

  export type PageCursor = {
    current_page: number,
		last_page: number,
		per_page: number,
		from: number,
		to: number,
		total: number
  }

  type MarangoResponseSuccess = {
    status: 'success';
    result: unknown;
    pages?: PageCursor
  };

  export function isMarangoResponseSuccess(
    obj: unknown,
  ): obj is MarangoResponseSuccess {
    return (
      (obj as MarangoResponseSuccess).status === 'success' &&
      (obj as MarangoResponseSuccess).result !== undefined
    );
  }

  export type MarangoResponseError = {
    readonly status: 'error';
    readonly errors: {
      readonly message:
        | string
        | Array<string>
        | Record<string, ReadonlyArray<string>>;
      readonly status_code?: number;
    };
  };

  export function isMarangoResponseError(
    obj: unknown,
  ): obj is MarangoResponseError {
    return (obj as MarangoResponseError).status === 'error';
  }

  type MarangoResponse = MarangoResponseError | MarangoResponseSuccess;

  export function isMarangoResponse(obj: unknown): obj is MarangoResponse {
    return isMarangoResponseSuccess(obj) || isMarangoResponseError(obj);
  }
}

export namespace MarangoApi {
  /** The serverId to connect to. this makes up part of the Server URL */
  export type ServerId = NominalPrimative<string, 'MarangoApi.ServerId'>;
  /** A Bearer Authentecation token returned by API */
  export type BearerToken = NominalPrimative<string, 'MarangoApi.BearerToken'>;

  export type Route =
    | 'api/auth/change-password'
    | 'api/auth/login'
    | 'api/version'
    | 'api/jobs'
    | `api/jobs/${FreightJobTypes.Id}`
    | 'api/contracts'
    | `api/contracts/${ContractTypes.Id}`
    | 'api/accounts/rctis'
    | 'api/stock/sites/levels'
    | 'api/stock/tickets'
    ;
}
export class MarangoApiService {
  constructor(readonly authToken: AuthTokenDetails) {}

  async getJobsForRange(range: DateRange): Promise<ReadonlyArray<FreightJob>> {
    const res = await MarangoApiService.handleRequest(
      {
        serverId: this.authToken.serverId,
        authToken: this.authToken.bearerToken,
        method: 'GET',
        route: 'api/jobs',
        queryParams: {
          start_date: range.start.toFormat('yyyy-LL-dd'),
          end_date: range.end.toFormat('yyyy-LL-dd'),
        },
      },
      MarangoJobsResponseDTO,
      false,
    );

    const jobs = res.result.toModel();

    return jobs.filter(j => j.details.length > 0);
  }

  async getJobForId(id: FreightJobTypes.Id): Promise<FreightJob | undefined> {
    try {
      const res = await MarangoApiService.handleRequest(
        {
          serverId: this.authToken.serverId,
          authToken: this.authToken.bearerToken,
          method: 'GET',
          route: `api/jobs/${id}`,
        },
        MarangoJobsResponseJobDTO,
        true,
      );

      return res.result.toModel();
    } catch (e) {
      return undefined;
    }
  }

  async getContracts(page: number = 1): Promise<MarangoApiResponse.Paginated<ReadonlyArray<Contract>>> {
    const [resPurchases, resSales] = await Promise.all([
      MarangoApiService.handleRequest(
        {
          serverId: this.authToken.serverId,
          authToken: this.authToken.bearerToken,
          method: 'GET',
          route: 'api/contracts',
          queryParams: {
            type: 'P',
            page: `${page}`
          },
        },
        MarangoContractsResponseDTO,
        false,
      ),
      MarangoApiService.handleRequest(
        {
          serverId: this.authToken.serverId,
          authToken: this.authToken.bearerToken,
          method: 'GET',
          route: 'api/contracts',
          queryParams: {
            type: 'S',
            page: `${page}`
          },
        },
        MarangoContractsResponseDTO,
        false,
      ),
    ]);

    return {
      result: [...resPurchases.result.toModel(), ...resSales.result.toModel()],
      pages: resPurchases.pages!.last_page > resSales.pages!.last_page ? resPurchases.pages : resSales.pages,
    };
  }

  async getTickets(page: number = 1): Promise<MarangoApiResponse.Paginated<ReadonlyArray<Ticket>>> {
    const res = await MarangoApiService.handleRequest(
      {
        serverId: this.authToken.serverId,
        authToken: this.authToken.bearerToken,
        method: 'GET',
        route: 'api/stock/tickets',
        queryParams: {
          page: `${page}`
        },
      },
      MarangoTicketsResponseDTO,
      false,
    );

    return {
      result: res.result.toModel(),
      pages: res.pages
    }
  }

  async getTicketById(id: TicketTypes.Id): Promise<Ticket> {
    let ticket: Ticket | undefined = undefined;
    let page = 1

    do {
      const { pages, result } = await this.getTickets(page);
      console.log(result)
      console.log(id)

      ticket = result.find(t => t.ticketId === id)
      if (pages.current_page === pages.last_page) {
        break;
      }
    } while (ticket === undefined);

    if (ticket === undefined) {
      throw new MarangoApiError({ message: "Ticket not found" })
    }
    return ticket
  }

  async getRCTIs(): Promise<ReadonlyArray<RCTI>> {
    const transactions = await MarangoApiService.handleRequest(
        {
          serverId: this.authToken.serverId,
          authToken: this.authToken.bearerToken,
          method: 'GET',
          route: 'api/accounts/rctis'
        },
        MarangoRCTIsResponseDTO,
        false,
      )

    return transactions.result.toModel();
  }

  async getWarehouseLevels(): Promise<ReadonlyArray<Warehouse>> {
    const sites = await MarangoApiService.handleRequest(
      {
        serverId: this.authToken.serverId,
        authToken: this.authToken.bearerToken,
        method: 'GET',
        route: 'api/stock/sites/levels'
      },
      MarangoWarehouseSitesResponseDTO,
      false
    )

    return sites.result.toModel()
  }

  async getContractForId(id: ContractTypes.Id): Promise<Contract> {
    const res = await MarangoApiService.handleRequest(
      {
        serverId: this.authToken.serverId,
        authToken: this.authToken.bearerToken,
        method: 'GET',
        route: `api/contracts/${id}`,
      },
      MarangoContractDetailResponseDTO,
      false,
    );

    return res.result.toModel();
  }

  async changePassword(request: ChangePasswordRequest.Detail): Promise<void> {
    await MarangoApiService.handleRequest(
      {
        serverId: this.authToken.serverId,
        authToken: this.authToken.bearerToken,
        method: 'POST',
        route: 'api/auth/change-password',
        body: {
          current_password: request.currentPassword,
          new_password: request.newPassword,
          // invalidate: true,
        }
      },
      MarangoChangePasswordResponseDTO,
      false
    )
  }

  version(): Promise<MarangoVersionResponseDTO> {
    return MarangoApiService.handleRequest(
      {
        serverId: this.authToken.serverId,
        authToken: this.authToken.bearerToken,
        method: 'GET',
        route: 'api/version',
      },
      MarangoVersionResponseDTO,
    ).then(res => res.result);
  }

  private static getBaseUrl(
    serverId: MarangoApi.ServerId | UserLoginDetails.ServerId,
  ) {
    return `https://syd${serverId}.db.marango.com.au`;
  }

  private static async handleRequest<Req, Res>(
    requestOptions: MarangoRequest<Req>,
    cls: ClassConstructor<Res>,
    unwrapBody: boolean = true,
  ): Promise<MarangoApiResponse.Paginated<Res>> {
    const { body, method, route, serverId } = requestOptions;

    const baseUrl = MarangoApiService.getBaseUrl(serverId);
    const request = new Request(
      `${baseUrl}/${route}${
        requestOptions.queryParams !== undefined
          ? `?${new URLSearchParams(requestOptions.queryParams)}`
          : ''
      }`,
      {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(requestOptions.authToken && {
            Authorization: `Bearer ${requestOptions.authToken}`,
          }),
        },
        body:
          requestOptions.body !== undefined ? JSON.stringify(body) : undefined,
      },
    );

    const res = await fetch(request);
    const bodyRaw = (await res.json()) as unknown;

    if (!MarangoApiResponse.isMarangoResponse(bodyRaw)) {
      throw new MarangoApiInvalidResponseError('Unknown response from API');
    }

    if (MarangoApiResponse.isMarangoResponseError(bodyRaw)) {
      throw new MarangoApiError(bodyRaw.errors);
    }

    const instance = plainToInstance(
      cls,
      unwrapBody ? bodyRaw.result : bodyRaw,
    );
    await validate(instance as unknown as object);

    return {
      result: instance,
      pages: bodyRaw['pages']!
    };
  }

  static async login(
    userDetails: UserLoginDetails,
  ): Promise<MarangoApiService> {
    const loginResponse = await this.handleRequest(
      {
        serverId: userDetails.serverId,
        method: 'POST',
        route: 'api/auth/login',
        body: {
          user: userDetails.username,
          password: userDetails.password,
        },
      },
      MarangoLoginResponseDTO,
    );

    return new MarangoApiService({
      serverId: userDetails.serverId as string as MarangoApi.ServerId,
      bearerToken: loginResponse.result.token,
      expires: loginResponse.result.expires,
    });
  }
}
