import React from 'react';
import { Box, Center, HStack, VStack } from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';

import { RCTI } from '@marango/api/src/models/Grower/RCTI';
import { CurrencyFormatter } from '@marango/api/src/formatters/currencyFormatter';
import Label from '../../atoms/Label';
import Bubble from '../../atoms/Bubble';

const InvoiceCell = (props: { rcti: RCTI, marginTop?: boolean }) => {
  const amountColor: ColorType = props.rcti.paid ? 'green.500' : 'red.500';

  return (
    <Bubble background="gray.100" marginBottom marginTop={props.marginTop} marginX>
      <VStack>
        <Center paddingBottom={2}>
          <HStack space={2}>
            <Label variant="large" bold>
              Invoice # - {props.rcti.invoiceNo}
            </Label>
          </HStack>
        </Center>
        <HStack flex={1} justifyContent="space-between">
          <VStack>
            <Label>Due: {props.rcti.dueDate}</Label>
            <Label>Received: {props.rcti.invoiceDate}</Label>
          </VStack>
          <Box flex={1}>
            <VStack
              height="100%"
              justifyContent="space-between"
              alignItems="flex-end">
              <Label color={amountColor} bold>
                {CurrencyFormatter(props.rcti.invoiceAmount)}
              </Label>
              <Label>Paid: {props.rcti.paidDate ?? '--'}</Label>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    </Bubble>
  );
};

export default InvoiceCell;
