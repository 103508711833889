import { DateTime } from "luxon";
import { ITimestamped } from "../../time/ITimestamped";
import { CommodityTypes, TicketTypes } from "./Types";

export class Ticket implements ITimestamped {
  public constructor(
    readonly ticketId: TicketTypes.Id,
    readonly ticketNo: TicketTypes.Number,
    readonly timestamp: DateTime,
    readonly inOut: TicketTypes.InOut,

    readonly commoditySeason: CommodityTypes.Season,
    readonly commodityName: CommodityTypes.Name,
    readonly commodityGrade: CommodityTypes.Grade | undefined,
    readonly commodityVariety: CommodityTypes.Variety | undefined,

    readonly massGross: string,
    readonly massTare: string,
    readonly massNet: string,
    readonly unitCode: string,

    readonly metrics: ReadonlyArray<Metric>,

    readonly carrierName: string | undefined,
    readonly carrierRego: string | undefined,

    readonly siteName: string | undefined,
    readonly siteAddress: string | undefined,
    readonly sitePhone: string | undefined,
  ) { }

  get commodityKey(): string {
    let str: string = this.commodityName

    if (this.commodityGrade) {
      str += ` - ${this.commodityGrade}`
    }

    if (this.commodityVariety) {
      str += ` - ${this.commodityVariety}`
    }

    return str
  }

  get inOutString(): string {
    switch (this.inOut) {
      case TicketTypes.InOut.Out: return "Out (Delivery)";
      case TicketTypes.InOut.In: return "In (Receiving)";
    }
  }
}

export class Metric {
  public constructor(
    readonly name: string,
    readonly value: number
  ) {}
}