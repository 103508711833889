import React, { useCallback, useState } from 'react';
import { VStack, HStack, Box, Pressable } from 'native-base';
import styled from 'styled-components';
import { ColorType } from 'native-base/lib/typescript/components/types';

import ScheduleListCellContainer from '../ScheduleTable/ScheduleTableCellContainer';

import Label from '../../atoms/Label';
import { Colors } from '../../../Colors';
import { Icon } from '../../atoms/Icon';
import { Contract } from '@marango/api/src/models/Grower/Contract';
import { dateStringFromLuxon } from '@marango/api/src/models/DateString';
import DateFormatter from '@marango/api/src/formatters/dateFormatter';
interface Props {
  contract: Contract;
  onSelect: (contract: Contract) => void;
}

const IconWrapper = styled(Box)({
  height: "25px",
  width: "25px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const ScheduleTableContractDetailCell = (props: Props) => {
  const [bgColor, setBgColor] = useState<ColorType | undefined>();

  const onPressIn = useCallback(() => {
    setBgColor(Colors.DARK_GREY.alpha(0.5).rgb().string());
  }, [setBgColor]);

  const onHoverIn = useCallback(() => {
    setBgColor(Colors.DARK_GREY.alpha(0.2).rgb().string());
  }, [setBgColor]);

  const onPressOut = useCallback(() => {
    setBgColor(undefined);
  }, [setBgColor]);

  const onSelect = useCallback(() => {
    props.onSelect(props.contract);
  }, [props.onSelect]);

  return (
    <Pressable
      borderBottomWidth="1"
      borderBottomColor={Colors.LIGHT_GREY.hex()}
      bgColor={bgColor}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onHoverIn={onHoverIn}
      onHoverOut={onPressOut}
      onPress={onSelect}>
      <ScheduleListCellContainer>
        <HStack justifyContent="stretch" height={50}>
          <Box flexBasis="100" flexShrink={0}>
            <VStack height="100%" justifyContent="space-between">
              <Label bold>{props.contract.contractNo}</Label>
              <Label>{DateFormatter(props.contract.timestamp, 'complete')}</Label>
            </VStack>
          </Box>

          <Box flexGrow="1" flexShrink="1">
            <VStack height="100%" justifyContent="space-between">
              <Label>{props.contract.commodityKey}</Label>
              <Label>Season: {props.contract.commoditySeason}</Label>
            </VStack>
          </Box>

          <Box flexBasis="70" flexShrink={0}>
            <VStack height="100%">
              <HStack alignItems="center" justifyContent="space-between">
                <IconWrapper>
                  <Icon icon="contract" />
                </IconWrapper>
                <Label>{props.contract.unitsContracted}</Label>
              </HStack>
              <HStack alignItems="center"  justifyContent="space-between">
                <IconWrapper>
                  <Icon icon="truck" />
                </IconWrapper>
                <Box>
                  <Label>--</Label>
                </Box>
              </HStack>
            </VStack>
          </Box>

          <Box justifyContent="center">
            <Box paddingLeft="2.5">
             <Icon icon="chevronRight" />
            </Box>
          </Box>
        </HStack>
      </ScheduleListCellContainer>
    </Pressable>
  );
};

export default ScheduleTableContractDetailCell;
