import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { DateTime } from "luxon";
import { Box } from "native-base";
import { StackScreenProps } from "@react-navigation/stack";
import { useInfiniteQuery } from "@tanstack/react-query";

import { MarangoApiResponse } from "@marango/api/src/Services/MarangoApi";
import { TicketsParamList } from "./GrowerRootStackNavigator";
import { AsyncLoadable } from "@marango/api/src/AsyncLoadable";
import { DateRange } from "@marango/api/src/time/dateRange";
import ScheduleTablePage from "../../Components/pages/ScheduleTablePage";
import { useGrowerRootStore } from "../../Stores/GrowerPortal/GrowerRootStoreProvider";
import { Colors } from "../../Colors";
import { Ticket } from "@marango/api/src/models/Grower/Ticket";
import ScheduleTableTicketDetailCell from "../../Components/molecules/Grower/ScheduleTableTicketDetailCell";

const DEFAULT_DATE_RANGE: DateRange = { start: DateTime.now(), end: DateTime.now().plus({ days: 1 }) };

type Props = StackScreenProps<TicketsParamList, 'list'>;

const TicketListPageContainer = observer<Props>(props => {
  const { navigationStore, userStore } = useGrowerRootStore()

  const {
    data,
    error,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch
  } = useInfiniteQuery<MarangoApiResponse.Paginated<readonly Ticket[]>, Error>(
    ['tickets'], ({ pageParam }) => userStore.getMarangoApiOrError().getTickets(pageParam),
    {
      getNextPageParam: p => p.pages.current_page === p.pages.last_page ? undefined : p.pages.current_page,
    }
  )

  const onRefresh = useCallback(() => {
    refetch()
  }, [refetch]);

  const onEndReached = useCallback(() => {
    fetchNextPage()
  }, [fetchNextPage]);

  const onTicketSelected = useCallback(
    (ticket: Ticket) => {
      navigationStore.goToTicketDetailPage(ticket.ticketId);
    },
    [navigationStore],
  );

  // Run this effect every time the data updates
  const { dateRange, errorString } = useMemo(() => {
    if (data) {
      const allData = data.pages.reduce<Ticket[]>((all, next) => ([...all, ...next.result]), [])
      const sortedData = Array.from(allData).sort((a, b) => a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0)
      const start = sortedData[0].timestamp
      let end: DateTime
      if (start.diff(sortedData[sortedData.length-1].timestamp).as('days') >= 1) {
        end = sortedData[sortedData.length-1].timestamp
      } else {
        end = start.plus({ days: 1 })
      }

      return {
        dateRange: { start, end },
        errorString: undefined
      }
    } else if (error) {
      return {
        dateRange: undefined,
        errorString: error.message,
      }
    } else {
      return {
        dateRange: undefined,
        errorString: undefined,
      }
    }
  }, [
    data
  ]);

  const tableData = AsyncLoadable.fromTanstackQuery(
    data?.pages.reduce<Ticket[]>((all, next) => ([...all, ...next.result]), []),
    errorString,
    isFetching,
    isFetchingNextPage
  )

  return <ScheduleTablePage
    range={dateRange ?? DEFAULT_DATE_RANGE}
    headerDateStyle="fixed"
    hideEmptyDays
    title="Active Contracts"
    hideHeader
    data={tableData}
    onRefresh={onRefresh}
    onEndReached={onEndReached}
    renderDetail={(t, i, isFirst, isLast) => {
      return (
        <Box mx="2" mt="2" mb={isLast ? "2" : "0"}>
          <ScheduleTableTicketDetailCell
            key={i}
            ticket={t}
            onSelect={onTicketSelected}
          />
        </Box>
      );
    }}
  />
})

export default TicketListPageContainer;