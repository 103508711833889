import _ from 'lodash';
import { Type } from 'class-transformer';
import {
  IsArray,
  IsDateString,
  IsEnum,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

import { ToModel } from '../models/ToModel';
import { CommodityTypes, TicketTypes } from '../models/Grower/Types';
import { DateString, dateStringToLuxon } from '../models/DateString';
import { MarangoApiResponseCommodityDTO, MarangoApiResponseCommodityGradeDTO, MarangoApiResponseCommodityVarietyDTO, MarangoApiResponseUnitDTO } from './MarangoJobsResponseDTO';
import { Metric, Ticket } from '../models/Grower/Ticket';

export class MarangoTicketsResponseDTO
  implements ToModel<ReadonlyArray<Ticket>>
{
  @IsArray()
  @ValidateNested()
  @Type(_ => MarangoTicketsResponseTicketDTO)
  result!: ReadonlyArray<MarangoTicketsResponseTicketDTO>;

  toModel(): ReadonlyArray<Ticket> {
    return this.result.map(j => j.toModel());
  }
}

export class MarangoTicketsResponseTicketDTO implements ToModel<Ticket> {
  @IsNumber()
  stock_ticket_uid!: number

  @IsString()
  ticket_no!: string

  @IsEnum([-1, 1])
  in_out!: TicketTypes.InOut

  @IsDateString()
  ticket_date!: DateString

  @IsString()
  commodity_season!: CommodityTypes.Season

  @IsString()
  gross!: string

  @IsString()
  tare!: string

  @IsString()
  units!: string

  @IsString()
  carrier_name!: string

  @IsString()
  carrier_rego!: string

  @IsArray()
  @ValidateNested()
  @Type(_ => MarangoApiResponseTicketMetricDTO)
  ticket_metric!: ReadonlyArray<MarangoApiResponseTicketMetricDTO>

  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityDTO)
  commodity!: MarangoApiResponseCommodityDTO;

  @IsOptional()
  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityGradeDTO)
  commodity_grade!: MarangoApiResponseCommodityGradeDTO | null;

  @IsOptional()
  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityVarietyDTO)
  commodity_variety!: MarangoApiResponseCommodityVarietyDTO | null;

  @ValidateNested()
  @Type(_ => MarangoApiResponseUnitDTO)
  unit!: MarangoApiResponseUnitDTO;

  @ValidateNested()
  @Type(_ => MarangoApiResponseTicketStorageSiteDTO)
  storage_site!: MarangoApiResponseTicketStorageSiteDTO;

  toModel(): Ticket {
    return new Ticket(
      `${this.stock_ticket_uid}` as TicketTypes.Id,
      this.ticket_no as TicketTypes.Number,
      dateStringToLuxon(this.ticket_date),
      this.in_out,
      this.commodity_season,
      this.commodity.getCommodityName(),
      this.commodity_grade?.getCommodityGrade(),
      this.commodity_variety?.getCommodityVariety(),
      this.gross,
      this.tare,
      this.units,
      this.unit.unit_code,
      this.ticket_metric.map(m => m.toModel()),
      this.carrier_name,
      this.carrier_rego,
      this.storage_site.site_name,
      this.storage_site.site_address,
      this.storage_site.contact_phone
    );
  }
}

export class MarangoApiResponseTicketMetricDTO implements ToModel<Metric> {
  @IsNumber()
  readonly value!: number;

  @IsObject()
  readonly metric!: { metric_name: string }

  toModel(): Metric {
    return new Metric(
      this.metric.metric_name,
      this.value
    )
  }
}

export class MarangoApiResponseTicketStorageSiteDTO {
  @IsString()
  readonly site_name!: string;

  @IsString()
  readonly site_address!: string;

  @IsString()
  readonly contact_phone!: string;
}
