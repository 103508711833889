import { Image } from 'native-base';
import { Image as RNImage } from 'react-native';
import React, { useEffect, useState } from 'react';
import { ImageURISource } from 'react-native';
import assertNever from '@marango/api/src/utils/assertNever';

import marangoLogoBlueImg from '../../Resources/logo-blue-square.png';
import marangoLogoPurpleImg from '../../Resources/logo-purple.png';
import crokerGrainImg from '../../Resources/croker-grain.png'

export type LogoColor = 'blue' | 'purple' | 'crocker';

type Props = {
  color?: LogoColor;
  compact?: boolean;
};

const MarangoLogo = (props: Props = {}) => {
  const [aspectRatio, setAspectRatio] = useState<number>(0)

  let src: ImageURISource = {};
  if (!props.color) {
    src = { uri: marangoLogoBlueImg };
  } else {
    switch (props.color) {
      case 'blue': {
        src = { uri: marangoLogoBlueImg };
        break;
      }
      case 'purple': {
        src = { uri: marangoLogoPurpleImg };
        break;
      }
      case 'crocker': {
        src = { uri: crokerGrainImg }
        break;
      }
      default:
        assertNever(props.color);
    }
  }

  useEffect(() => {
    if (!src.uri) {
      setAspectRatio(0)
      return
    }

    RNImage.getSize(src.uri, (width, height) => {
      setAspectRatio(width / height)
    })
  }, [src.uri])


  return (
    <Image alt="logo" source={src} resizeMode="contain" style={{ aspectRatio, width: "100%" }} />
  );
};

export default MarangoLogo;
