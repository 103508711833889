import React, { useState, useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Center, VStack } from 'native-base';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import DialogBox from '../atoms/DialogBox';
import MarangoLogo from '../atoms/MarangoLogo';
import type { LogoColor } from '../atoms/MarangoLogo';
import { LoginForm } from '../organism/LoginForm';
import InputFormContainer from '../molecules/Layout/InputFormContainer';

type Props = {
  serverId?: string;
  onLogin: (request: LoginForm.RequestDetails) => void | Promise<void>;
  error?: string;
  isLoading: boolean;
  logoColor?: LogoColor
};

const LoginPage = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const safeAreaInsets = useSafeAreaInsets();

  useEffect(() => {
    if (props.error) {
      setErrorMessage(props.error);
    }
  }, [props.error]);

  const loginCallback = useCallback(
    async (response: LoginForm.RequestResponse) => {
      if (response._type === 'validationError') {
        setErrorMessage(response.message);
      } else {
        setErrorMessage(undefined);
        await props.onLogin(response.details);
      }
    },
    [setErrorMessage, props],
  );

  return (
    <VStack height="100%">
      <KeyboardAwareScrollView
        enableAutomaticScroll
        enableOnAndroid
        style={stylesheet.scrollView}>
        <Box height={safeAreaInsets.top} />
        <InputFormContainer>
          <MarangoLogo color={ props.logoColor ?? "blue"} />
          <LoginForm
            hideServerId={props.serverId !== undefined}
            serverId={props.serverId}
            onLoginRequest={loginCallback}
            isLoading={props.isLoading}
          />

          {errorMessage && (
            <Box mt="5">
              <DialogBox
                title="Error Logging In"
                description={errorMessage}
                severity="error"
              />
            </Box>
          )}
        </InputFormContainer>
      </KeyboardAwareScrollView>
    </VStack>
  );
};

const stylesheet = StyleSheet.create({
  scrollView: {
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
  },
  errorContainer: {
    marginTop: 10,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default LoginPage;
