import { DateTime } from 'luxon';
import { DateRange } from './dateRange';

export default function dayRange(range: DateRange): Array<DateTime> {
  if (range.end.diff(range.start).valueOf() <= 0) {
    throw new RangeError(
      'Could not create DayRange list, endDate was equal to or before startDate',
    );
  }

  const dates: Array<DateTime> = [];

  // The provided start & end date
  let currentDate = range.start.startOf('day');
  const limitDate = range.end.endOf('day');
  do {
    dates.push(currentDate);
    currentDate = currentDate.plus({ days: 1 });
  } while (currentDate.diff(limitDate).valueOf() <= 0);

  return dates;
}
