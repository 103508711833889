import React, { useCallback, useEffect, useState } from "react";
import { Box } from "native-base";
import { observer } from "mobx-react-lite";
import { DateTime } from "luxon";
import { useNavigation } from "@react-navigation/core";
import { StackScreenProps } from "@react-navigation/stack";
import { useInfiniteQuery } from "@tanstack/react-query";

import { DateRange } from "@marango/api/src/time/dateRange";
import { AsyncLoadable } from "@marango/api/src/AsyncLoadable";
import { RCTI } from "@marango/api/src/models/Grower/RCTI";
import { InvoicesParamList } from "./GrowerRootStackNavigator";
import ScheduleTablePage from "../../Components/pages/ScheduleTablePage";
import InvoiceCell from "../../Components/molecules/Grower/InvoiceCell";
import { useGrowerRootStore } from "../../Stores/GrowerPortal/GrowerRootStoreProvider";
import IconButton from "../../Components/atoms/IconButton";

type Props = StackScreenProps<InvoicesParamList, 'list'>;

const InvoiceListPageContainer = observer<Props>(props => {
  const { userStore } = useGrowerRootStore()
  const navigation = useNavigation();
  const [dateRange, setDateRange] = useState<DateRange>({ start: DateTime.now(), end: DateTime.now().plus({ days: 1 }) })

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch
  } = useInfiniteQuery(
    ['invoices' ], ({ pageParam }) => userStore.getMarangoApiOrError().getRCTIs(),
    {
      // getNextPageParam: p => p.pages.current_page === p.pages.last_page ? undefined : p.pages.current_page,
      getNextPageParam: p => undefined,
    }
  )

  const onRefresh = useCallback(() => {
    refetch()
  }, [refetch]);

  // Run this effect every time the data updates
  useEffect(() => {
    if (data) {
      const allData = data.pages.reduce<RCTI[]>((all, next) => ([...all, ...next]), [])
      const sortedData = Array.from(allData).sort((a, b) => a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0)
      const startTimestamp = sortedData[0].timestamp
      const endTimestamp = sortedData[sortedData.length-1].timestamp

      setDateRange({ start: startTimestamp, end: endTimestamp })
    }
  }, [
    data
  ]);

  useEffect(() => {
    const headerLeft = () => (
      <Box marginX="3">
        <IconButton icon="refresh" onPress={onRefresh} />
      </Box>
    );

    navigation.setOptions?.({ headerLeft })
  }, [navigation])

  const tableData = AsyncLoadable.fromTanstackQuery(
    data?.pages.reduce<RCTI[]>((all, next) => ([...all, ...next]), []),
    error as string,
    isFetching,
    isFetchingNextPage
  )

  return <ScheduleTablePage
    range={dateRange}
    headerDateStyle="fixed"
    title="Invoices"
    hideEmptyDays
    hideItemSeperator
    hideHeader
    onEndReached={fetchNextPage}
    data={tableData}
    onRefresh={onRefresh}
    renderDetail={(rcti, i) => {
      return (
        <InvoiceCell
          key={i}
          rcti={rcti}
          marginTop
        />
      );
    }}
  />
})

export default InvoiceListPageContainer;