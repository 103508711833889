import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { DateTime } from "luxon";
import { Box } from "native-base";
import { StackScreenProps } from "@react-navigation/stack";
import { useInfiniteQuery } from "@tanstack/react-query";

import { MarangoApiResponse } from "@marango/api/src/Services/MarangoApi";
import { ContractsParamList } from "./GrowerRootStackNavigator";
import { AsyncLoadable } from "@marango/api/src/AsyncLoadable";
import { DateRange } from "@marango/api/src/time/dateRange";
import { Contract } from "@marango/api/src/models/Grower/Contract";
import ScheduleTablePage from "../../Components/pages/ScheduleTablePage";
import { useGrowerRootStore } from "../../Stores/GrowerPortal/GrowerRootStoreProvider";
import ScheduleTableContractDetailCell from "../../Components/molecules/Grower/ScheduleTableContractDetailCell";
import { Colors } from "../../Colors";

const DEFAULT_DATE_RANGE: DateRange = { start: DateTime.now(), end: DateTime.now().plus({ days: 1 }) };

type Props = StackScreenProps<ContractsParamList, 'list'>;

const ContractListPageContainer = observer<Props>(props => {
  const { navigationStore, userStore } = useGrowerRootStore()

  const {
    data,
    error,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch
  } = useInfiniteQuery<MarangoApiResponse.Paginated<readonly Contract[]>, Error>(
    ['contracts'], ({ pageParam }) => userStore.getMarangoApiOrError().getContracts(pageParam),
    {
      getNextPageParam: p => p.pages.current_page === p.pages.last_page ? undefined : p.pages.current_page,
    }
  )

  const onRefresh = useCallback(() => {
    refetch()
  }, [refetch]);

  const onEndReached = useCallback(() => {
    fetchNextPage()
  }, [fetchNextPage]);

  const onContractSelected = useCallback(
    (contract: Contract) => {
      navigationStore.goToContractDetailPage(contract.contractId);
    },
    [navigationStore],
  );

  // Run this effect every time the data updates
  const { dateRange, errorString } = useMemo(() => {
    if (data) {
      const allData = data.pages.reduce<Contract[]>((all, next) => ([...all, ...next.result]), [])
      const sortedData = Array.from(allData).sort((a, b) => a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0)
      const start = sortedData[0].timestamp
      const end = sortedData[sortedData.length-1].timestamp

      return {
        dateRange: { start, end },
        errorString: undefined
      }
    } else if (error) {
      return {
        dateRange: undefined,
        errorString: error.message,
      }
    } else {
      return {
        dateRange: undefined,
        errorString: undefined,
      }
    }
  }, [
    data
  ]);

  const tableData = AsyncLoadable.fromTanstackQuery(
    data?.pages.reduce<Contract[]>((all, next) => ([...all, ...next.result]), []),
    errorString,
    isFetching,
    isFetchingNextPage
  )

  return <ScheduleTablePage
    range={dateRange ?? DEFAULT_DATE_RANGE}
    headerDateStyle="fixed"
    hideEmptyDays
    title="Active Contracts"
    hideHeader
    data={tableData}
    onRefresh={onRefresh}
    onEndReached={onEndReached}
    renderDetail={(c, i, isFirst) => {
      return (
        <Box borderTopColor={Colors.LIGHT_GREY.hex()} borderTopWidth={isFirst ? 1 : 0}>
          <ScheduleTableContractDetailCell
            key={i}
            contract={c}
            onSelect={onContractSelected}
          />
        </Box>
      );
    }}
  />
})

export default ContractListPageContainer;